<template>
  <div class="login_container">
    <div class="login_main">
      <img
        :src="require('@/assets/images/login/logo.png')"
        class="login_banner"
      />
      <div class="login_title">执行人登录</div>
      <div class="login_block">
        <!-- <div class="login_block_title">登录</div> -->
        <el-input
          v-model="data.phone"
          @input="inputField('phone')"
          type="text"
          placeholder="输入账号"
        >
          <template #suffix>
            <el-icon><Iphone /></el-icon> </template
        ></el-input>
        <el-input
          @input="inputField('passwd')"
          v-model="data.passwd"
          type="password"
          placeholder="输入密码"
        >
          <template #suffix>
            <el-icon><Lock /></el-icon> </template
        ></el-input>
        <div class="login_check">
          <el-checkbox v-model="data.status" size="small" />
          <span class="login_check_title"
            ><span>我已阅读并接受骨转百讯</span> 《
            <span @click="goToAgreement('user')" class="colorRed">用户协议</span
            >》《<span class="colorRed" @click="goToAgreement('privacy')"
              >隐私政策</span
            >》</span
          >
        </div>
        <div class="login_btn">
          <CustomButton
            size="small"
            :class="getStatus() ? 'login_activat' : 'login_submit'"
            @click="goToLogin"
          >
            登录</CustomButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import { Iphone, Lock } from "@element-plus/icons";
export default {
  name: "executorLogin",
  components: {
    CustomButton,
    Iphone,
    Lock,
  },
  data() {
    return {
      data: {
        phone: "",
        passwd: "",
        status: false,
      },

      loading: false,
    };
  },
  created() {
    this.data = {
      ...this.data,
      ...this.$tools.getStorage("executorLogin", localStorage),
    };
    document.getElementsByTagName("title")[0].innerHTML = "骨转百讯";
  },
  methods: {
    goToAgreement(type) {
      this.$router.push({
        path: `/${type}Agreement`,
      });
    },
    getStatus() {
      if (this.data.phone && this.data.passwd && this.data.status) return true;
    },
    formData() {
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^(\d{11}[a-zA-Z]?)?$/.test(this.data.phone)) {
        this.$tips.error({ text: "请输入正确的手机号" });
        return false;
      }
      if (!this.data.passwd) {
        this.$tips.error({ text: "请输入4位数字密码" });
        return false;
      }
      if (!/^(\d{4}[a-zA-Z]?)?$/.test(this.data.passwd)) {
        this.$tips.error({ text: "请输入4位数字密码" });
        return false;
      }
      return true;
    },
    inputField(key) {
      switch (key) {
        case "phone":
        case "passwd":
          this.data[key] = this.data[key].replace(/\D/g, "");
          break;
      }
    },
    goToLogin() {
      if (!this.getStatus()) {
        return;
      }
      if (!this.formData()) {
        return;
      }
      this.$axios
        .post(this.$urls.user.executorLogin, this.data)
        .then((res) => {
          this.$tools.setStorage("executorLogin", this.data, localStorage);
          this.$store.dispatch("setItem", { executorInfo: res || {} });
          this.$router.push({ path: "chatRoomList" });
        })
        .catch(() => {});
    },
  },
};
</script>
<style >
.login_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #fff8f8, #fde3f0);
}
.login_main {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_banner {
  width: 10rem;
  object-fit: contain;
  margin-bottom: 0.5rem;
}
.login_block {
  width: 90%;
  /* background: #fff; */
  margin: 1rem;
  padding: 1rem 0.5rem;
}
.login_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #e64638;
}
.login_block_title {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 3px;
  padding-left: 3px;
}
.login_block_title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  display: inline-block;
  width: 100%;
  height: 10px;
  background: #ebf4c4;
}
.login_block .el-input {
  margin-top: 0.5rem;
  /* font-size: 16px !important; */
}
.login_block .el-input__inner {
  /* background: #f7f7f7; */
  outline: none;
  border: none;
}
.login_check {
  margin: 0.5rem 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}
.login_check_title {
  margin-left: 0.25rem;
}
.login_btn .login_submit {
  height: 1.5rem;
  color: #999;
  background: #f2f2f2 !important;
  border: #f2f2f2 solid 1px !important;
}
.login_btn .login_activat {
  height: 1.5rem;
  background: #e64638 !important;
  border: #860241 solid 1px !important;
  color: #fff;
}
.login_block .el-icon {
  color: #222;
}
.login_container .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.login_container .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>